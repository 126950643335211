<template>
  <CModalExtended
    :title="TitleModal"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="showModal"
    @update:show="CloseModal"
  >
    <div>
      <CTabs variant="tabs" :active-tab="0" style="width: 100%;">
        <CTab :title="$t('label.DirectDischarge')">
          <CCard class="card-border">
            <CRow class="mx-0 mt-3">
              <CCol sm="12" lg="6">
                <div class="form-group form-row">
                  <label class="col-form-label-sm col-sm-12 col-lg-4 text-right required mb-0" >{{$t('label.transport')}}</label>
                  <div class="col-sm-12 col-lg-8 input-group-sm" style="height: 30px;">
                    <v-select
                      id="v-select-small"
                      class="select-adjust"
                      v-model="$v.StowagePlanningResourceId.$model"
                      :options="TransportOptions"
                      :reduce="option => option.value"
                      :placeholder="$t('label.select')"
                      :class="$v.StowagePlanningResourceId.$dirty ? ($v.StowagePlanningResourceId.$error ? 'select-client--error' : 'select-client--correct')  : ''"
                    >
                      <template #no-options="{ }">
                        {{$t('label.noResultsFound')}}
                      </template>
                    </v-select>
                  </div>
                </div>
                <CSelect
                  :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  addLabelClasses="text-right required"
                  size="sm"
                  :label="$t('label.driver')"
                  :options="DriverOptions"
                  v-model.trim="$v.StowagePlanningDriverId.$model"
                  :value.sync="StowagePlanningDriverId"
                  :is-valid="hasError($v.StowagePlanningDriverId)"
                  :invalid-feedback="errorMessage($v.StowagePlanningDriverId)"
                />
                <CSelect
                  :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  addLabelClasses="text-right required"
                  size="sm"
                  :label="$t('label.plate')"
                  :options="VehicleOptions"
                  v-model.trim="$v.StowagePlanningVehicleId.$model"
                  :value.sync="StowagePlanningVehicleId"
                  :is-valid="hasError($v.StowagePlanningVehicleId)"
                  :invalid-feedback="errorMessage($v.StowagePlanningVehicleId)"
                />
                <CSelect
                  v-if="isEdit"
                  :options="statusOptions"
                  :value.sync="Status"
                  size="sm"
                  :label="$t('label.status')"
                  :horizontal="{label:'col-sm-2 col-lg-4', input:'col-sm-10 col-lg-8'}"
                  addLabelClasses="required text-right"
                  :is-valid="statusSelectColor"
                />
              </CCol>
              <CCol sm="12" lg="6">
                <div class="form-group form-row mb-3 px-0">
                  <label class="col-form-label-sm col-sm-12 col-lg-4 required text-right mb-0">{{`${$t('label.date')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 align-items-center mb-0">
                    <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="false"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="$v.TransactionDate.$model"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                    >
                      <template #input>
                        <CInput
                          v-uppercase
                          class="w-100 float-left mb-0"
                          :value="TransactionDate"
                          placeholder="DD/MM/YYYY HH:mm"
                          :is-valid="hasError($v.TransactionDate)"
                          size="sm"                      
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                  </div>
                </div>
                <CInput
                  size="sm"
                  v-uppercase
                  v-model="$v.Destiny.$model"
                  :label="$t('label.destination')"
                  addLabelClasses="text-right"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  :placeholder="$t('label.destination')"
                  :is-valid="hasError($v.Destiny)"
                  :invalid-feedback="errorMessage($v.Destiny)"
                />
                <CTextarea
                  size="sm"
                  rows="2"
                  :label="$t('label.observation')"
                  addLabelClasses="text-right"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  :placeholder="$t('label.select')"
                  v-model="$v.Observation.$model"
                  v-uppercase
                  :is-valid="hasError($v.Observation)"
                  :invalid-feedback="errorMessage($v.Observation)"
                />
              </CCol>
            </CRow>
          </CCard>
          
        </CTab>
      </CTabs>
      <div class="mt-3">
        <table class="text-center mb-3" style="width: 100%;">
          <thead>
            <tr>
              <th></th>
              <th>
                <div class="row m-0 align-items-center justify-content-center">
                  <span class="circle planned-quantity mr-1"></span>
                  {{`${$t('label.planned')}`}}
                </div>
              </th>
              <th>
                <div class="row m-0 align-items-center justify-content-center">
                  <span class="circle identified-quantity mr-1"></span>
                  {{`${$t('label.Identified')}`}}
                </div>
              </th>
              <th>
                <div class="row m-0 align-items-center justify-content-center">
                  <span class="circle dispahed-quantity mr-1"></span>
                  {{`${$t('label.dispached')}`}}
                </div>
              </th>
              <th>
                <div class="row m-0 align-items-center justify-content-center">
                  <span class="circle remainder-quantity mr-1"></span>
                  {{`${$t('label.remainder')}`}}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right"><b>{{ $t('label.packaging') }}</b></td>
              <td>{{ FormatNumber(PackagingData?.QuantityPlanning, 2) }}</td>
              <td>{{ FormatNumber(PackagingData?.QuantityIdentified, 2) }}</td>
              <td>{{ FormatNumber(PackagingData?.QuantityDispatch, 2) }}</td>
              <td>{{ FormatNumber(PackagingData?.RemainDispatch, 2) }}</td>
            </tr>
            <tr>
              <td class="text-right"><b>BL</b></td>
              <td>{{ FormatNumber(BlData?.QuantityPlanning, 2) }}</td>
              <td>{{ FormatNumber(BlData?.QuantityIdentify, 2) }}</td>
              <td>{{ FormatNumber(BlData?.QuantityDispatch, 2) }}</td>
              <td>{{ FormatNumber(BlData?.RemainDispatch, 2) }}</td>
            </tr>
          </tbody>
        </table>

        <CRow class="mx-0">
          <CRow class="mx-0 col-sm-12 col-lg-10 px-0">
            <CCol sm="12" lg="6">
              <CSelect
                size="sm"
                v-uppercase
                :label="$t('label.packaging')"
                addLabelClasses="required text-right"
                :placeholder="$t('label.select')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                v-model="$v.Packaging.PackagingId.$model"
                :options="PackagingOptions"
                :is-valid="hasError($v.Packaging.PackagingId)"
                :invalid-feedback="errorMessage($v.Packaging.PackagingId)"
                @change="(Packaging.VisitBillOfLadingPackagingId='', getBl($event.target.value))"
              />
            </CCol>
            <CCol sm="12" lg="6">
              <CSelect
                size="sm"
                v-uppercase
                label="BL"
                addLabelClasses="required text-right"
                :placeholder="$t('label.select')"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                v-model="$v.Packaging.VisitBillOfLadingPackagingId.$model"
                :options="BlOptions"
                :is-valid="hasError($v.Packaging.VisitBillOfLadingPackagingId)"
                :invalid-feedback="errorMessage($v.Packaging.VisitBillOfLadingPackagingId)"
              />
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm">{{`${$t('label.quantity')}`}}</label>
                <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                  <money
                    size="sm"
                    v-bind="measure" 
                    :class="!$v.Packaging.Quantity.$dirty || CleanInformation ? 'form-control' : ($v.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                    v-model="$v.Packaging.Quantity.$model"
                    addLabelClasses="required text-right"
                    maxlength= "13"
                  >
                  </money>
                  <div class="invalid-feedback" v-if="$v.Packaging.Quantity.$error&&!CleanInformation">
                    <p class="mb-0" v-if="!$v.Packaging.Quantity.required">{{ errorMessage($v.Packaging.Quantity) }}</p>
                    <p class="mb-0" v-else-if="!$v.Packaging.Quantity.MaxValue">{{ `${$t('validation.maxValueIs')}: ${BlData?.RemainDispatch ?? 0}`}}</p>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <CTextarea
                size="sm"
                rows="2"
                :label="$t('label.observation')"
                addLabelClasses="text-right"
                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.select')"
                v-model="$v.Packaging.Observation.$model"
                v-uppercase
                :is-valid="hasError($v.Packaging.Observation)"
                :invalid-feedback="errorMessage($v.Packaging.Observation)"
              />
            </CCol>
          </CRow>        
          <CCol sm="12" lg="auto" style="text-align: left;">
            <CButton
              color="add"
              size="sm"
              class="mr-1"
              v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
              :disabled="isSubmit"
              @click="SubmitPackaging"
            >
              <CIcon name="checkAlt"/>
            </CButton>
            <CButton
              color="wipe"
              class="justify-content-end"
              size="sm"
              v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
              @click="CleanPackaging"
            >
              <CIcon name="cil-brush-alt" />
            </CButton>
          </CCol>
          
        </CRow>
        <div>
          <dataTableExtended
            class="align-center-row-datatable"
            :items-per-page="5"
            :items="ComputedPackaging"
            :fields="Packagingfields"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  color="edit"
                  square
                  size="sm"
                  class="mr-1"
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.packaging'),
                    placement: 'top-end'
                    }"
                  @click="EditPackaging(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
                <CButton
                 color="wipe"
                 size="sm"
                  v-c-tooltip="{
                    content: `${$t('label.delete')} BL`,
                    placement: 'top-end',
                  }"
                  @click="DeletePackaging(item)"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
          <div class="text-invalid-feedback text-center" v-if="$v.BillOfLadingJson.$error">
            {{ $t('validation.RequiredBl') }}
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <CButton
        color="add"
        class="m-2"
        :disabled="isSubmit"
        @click="submit"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept') }}</span>
      </CButton>
      <CButton
        color="wipe"
        class="m-2"
        :disabled="isSubmit"
        @click="CloseModal"
      >
        <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
  </CModalExtended> 
</template>

<script>
import DirectDischargeValidations from '@/_validations/visitas/general-cargo/discharge/directDischargeValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import { mapState } from "vuex";
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { Money } from "v-money";

//Data
function data() {
  return {
    showModal: false,
    isSubmit: false,
    isEditMaster: false, 
    ModalNewDriver: false,
    ModalNewVehicle: false,
    CleanInformation: false,
    Start: false,
    StowagePlanningResourceId: '',
    TransportList: [],
    StowagePlanningDriverId: '',
    DriverList: [],
    StowagePlanningVehicleId: '',
    VehicleList: [],
    TransactionDate: '',
    Destiny: '',
    Observation: '',
    Packaging: {
      Id: '',
      PackagingId: '',
      VisitBillOfLadingPackagingId: '',
      Quantity: 0,
      Observation: '',
    },
    PackagingList: [],
    BlList: [],  
    BillOfLadingJson: [],
    previousDate: '',
    laterDate: '',
    Status: 1,
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
  }
}

//methods
async function Services() {
  let requests = [
    this.$http.get('VisitResourceCarrierAll-list',{ VisitId: this.VisitId }),
    this.$http.get('VisitGeneralCargoImportPackagingIdentifiedResum-by-VisitId',{ VisitId: this.VisitId }),
  ];
  await Promise.all(requests)
    .then(responses => {
      this.TransportList = responses[0].data.data ?? [];
      this.PackagingList = responses[1].data.data ?? [];
    })
    .catch( err => {
      this.TransportList = [];
      this.PackagingList = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function submit() {
  try {
    this.isSubmit = true;
    this.$emit('Loading', true);
    this.$v.OrderJson.$touch();

    if (this.$v.OrderJson.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
  
    let OrderJson = {
      VisitId: this.VisitId,
      StowagePlanningResourceId: this.StowagePlanningResourceId,
      StowagePlanningDriverId: this.StowagePlanningDriverId,
      StowagePlanningVehicleId: this.StowagePlanningVehicleId,
      Destiny: this.Destiny ?? '',
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
      Observation: this.Observation,
      BillOfLadingJson: this.BillOfLadingJson.map(item => {
        return {
          OrderDetailBillOfLadingId: item.OrderDetailBillOfLadingId ?? '',
          VisitBillOfLadingPackagingId: item.VisitBillOfLadingPackagingId ?? '',
          Quantity: item.Quantity ?? 0,
          Observation: item.Observation ?? '',
        }
      })
    };

    if (this.isEdit) {
      OrderJson.OrderId = this.DirectDischargeItem.OrderId ?? '';
      OrderJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'VisitGeneralCargoDispatchOrder-update' : 'VisitGeneralCargoDispatchOrder-insert';

    this.$http.ejecutar(Method, Route, OrderJson, { root: 'OrderJson' } )
      .then(response => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.$emit("Update");
        this.CloseModal();
      })
      .catch( err => {
        this.$emit('Loading', false);
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
      .finally(() => {
        this.isSubmit = false;
      });
  } catch (Error) {
    this.$emit('Loading', false);
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: Error,
      type: "error"
    });
  }
}

async function getBl(Id) {
  if (Id && typeof Id == 'string') {
    this.$emit('Loading', true);
    await this.$http.post('VisitBillOfLadingImportPackagingDispatch-list',{ VisitId: this.VisitId, PackagingId: Id }, { root: 'VisitJson' })
      .then(response => {
        let List = response.data.data ?? [];
        if (Array.isArray(List)) {
          this.BlList = [...List];
        }else{
          this.BlList.push(List);
        }
      })
      .catch( err => {
        this.BlList = [];
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
      .finally(() => {
        this.$emit('Loading', false);
      });
  } else {
    this.BlList = [];
  }
  
}

async function SubmitPackaging() {
  try {
    this.isSubmit = true;
    this.$v.Packaging.$touch();
    if (this.$v.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    if (!this.BillOfLadingJson.some(item => item.PackagingId == this.Packaging.PackagingId && item.VisitBillOfLadingPackagingId == this.Packaging.VisitBillOfLadingPackagingId)) {
      this.BillOfLadingJson.push({
        VisitBillOfLadingPackagingId: this.Packaging.VisitBillOfLadingPackagingId,
        PackagingId: this.Packaging.PackagingId,
        NroBl: this.BlData.NroBl ?? '',
        Quantity: this.Packaging.Quantity,
        PackagingNameEn: this.PackagingData?.PackagingNameEn ?? 'N/A',
        PackagingNameEs: this.PackagingData?.PackagingNameEs ?? 'N/A',
        Observation: this.Packaging.Observation,
        Status: 1,
      });
    }else{
      this.BillOfLadingJson = this.BillOfLadingJson.map(item => {
        if (item.PackagingId == this.Packaging.PackagingId && item.VisitBillOfLadingPackagingId == this.Packaging.VisitBillOfLadingPackagingId) {
          return {
            OrderDetailBillOfLadingId: item.OrderDetailBillOfLadingId ?? '',
            VisitBillOfLadingPackagingId: item.VisitBillOfLadingPackagingId ?? '',
            PackagingId: this.Packaging.PackagingId,
            NroBl: this.BlData.NroBl ?? '',
            Quantity: this.Packaging.Quantity,
            PackagingNameEn: this.PackagingData?.PackagingNameEn ?? 'N/A',
            PackagingNameEs: this.PackagingData?.PackagingNameEs ?? 'N/A',
            Observation: this.Packaging.Observation,
            Status: 1,
          }
        }else{
          return item;
        }
      });
    }
    await this.CleanPackaging(false);
    this.isSubmit = false;
    this.$v.$reset();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.OrderDetailBillOfLadingId) {
          this.BillOfLadingJson = this.BillOfLadingJson.map(BillOfLading => {
            if (item.PackagingId == BillOfLading.PackagingId && item.VisitBillOfLadingPackagingId == BillOfLading.VisitBillOfLadingPackagingId) {
              return {
                ...BillOfLading,
                Status: 0,
              };
            } else {
              return BillOfLading;
            }
          });
        }else{
          this.BillOfLadingJson = this.BillOfLadingJson.filter(BillOfLading => !(item.PackagingId == BillOfLading.PackagingId && item.VisitBillOfLadingPackagingId == BillOfLading.VisitBillOfLadingPackagingId));
        }
      }
    });
}

async function EditPackaging(item) {
  this.$emit('Loading', true);
  this.Packaging = {
    PackagingId: item.PackagingId ?? '',
    VisitBillOfLadingPackagingId: item.VisitBillOfLadingPackagingId,
    Quantity: item.Quantity,
    Observation: item.Observation,
  }
  await this.getBl(this.Packaging.PackagingId);
  this.$v.Packaging.$touch();
  this.$emit('Loading', false);
}

function CloseModal() {
  this.$emit('Close');
  this.Reset();
}

function getData(item) {
  this.Start = true;
  this.StowagePlanningResourceId = item.StowagePlanningResourceId ?? '';
  this.StowagePlanningDriverId = item.StowagePlanningDriverId ?? '';
  this.StowagePlanningVehicleId = item.StowagePlanningVehicleId ?? '';
  this.TransactionDate = item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '';
  this.Destiny = item.Destiny ?? '';
  this.Observation = item.Observation ?? '';
  this.Status = item.FgActOrder ? 1 : 0;
  this.BillOfLadingJson = item?.BillOfLadingJson ? 
    item?.BillOfLadingJson.map((BillOfLading, index) => {
      return {
        VisitBillOfLadingPackagingId: BillOfLading.VisitBillOfLadingPackagingId,
        PackagingId: BillOfLading.PackagingId,
        NroBl: BillOfLading.NroBl ?? '',
        Quantity: BillOfLading.Quantity,
        PackagingNameEn: BillOfLading?.PackagingNameEn ?? 'N/A',
        PackagingNameEs: BillOfLading?.PackagingNameEs ?? 'N/A',
        Observation: BillOfLading.Observation,
        Status: BillOfLading.FgActOrderDetailBl ? 1 : 0,
      }
    })
    : [];
  this.$v.OrderJson.$touch();
}

function getDateRange(){
  if(this.itinerarySelected.Ata!=undefined){ 
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
  }else{
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
  }

  if(this.itinerarySelected.Atd!=undefined){  
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
  }else{
    let currentDate = new Date();
    this.TransactionDate = DateFormater.formatTimeZoneToDateTime(currentDate);
    this.laterDate = currentDate;
  }

}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function statusSelectColor() {
  return this.Status === 1;
}

function FormatNumber(Number) {
  return NumberFormater.formatNumber(Number, 2);
}

function CleanPackaging() {
  this.CleanInformation = true;
  this.Packaging = {
    Id: '',
    PackagingId: '',
    VisitBillOfLadingPackagingId: '',
    Quantity: 0,
    Observation: '',
  }
  this.$v.Packaging.$reset();
  setTimeout(() => {
    this.$v.Packaging.$reset();
    this.CleanInformation = false;
  }, 1);
}

function Reset() {
  this.StowagePlanningResourceId = '';
  this.TransportList = [];
  this.StowagePlanningDriverId = '';
  this.VehicleList = [];
  this.StowagePlanningVehicleId = '';
  this.DriverList = [];
  this.TransactionDate = '';
  this.Destiny = '';
  this.Observation = '';
  this.Status = 1;
  this.Packaging = {
    Id: '',
    PackagingId: '',
    VisitBillOfLadingPackagingId: '',
    Quantity: 0,
    Observation: '',
  }
  this.PackagingList = [];
  this.BlList = [];
  this.BillOfLadingJson = [];
  this.isSubmit = false;
  this.CleanInformation = false;
  setTimeout(() => {
    this.$v.$reset();
  }, 1);
}

//computed
function TransportOptions() {
  let chart = [{
    label: this.$t('label.select'), 
    value: '',
  }]
  this.TransportList.map(item => {
    chart.push({
      label: item.Alias,
      value: item.StowagePlanningResourceId,
    })
  })
  return chart;
}

function DriverOptions() {
  let chart = [{
    label: this.$t('label.select'), 
    value: '',
  }]
  this.DriverList.map(item => {
    chart.push({
      label: `${item.DriverName} (${item.DriverCi})`,
      value: item.StowagePlanningDriverId,
    })
  })
  return chart;
}

function VehicleOptions() {
  let chart = [{
    label: this.$t('label.select'), 
    value: '',
  }]
  this.VehicleList.map(item => {
    chart.push({
      label: item.LicensePlate,
      value: item.StowagePlanningVehicleId,
    })
  })
  return chart;
}

function CiTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
    {
      value: "J",
      label: "J",
    },
  ];
}

function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    label: this.$t('label.select'), 
    value: '',
  }]
  this.PackagingList.map(item => {
    chart.push({
      value: item.PackagingId, 
      label: item['PackagingName'+_lang],
    })
  })
  return chart;
}

function BlOptions() {
  let chart = [{
    label: this.$t('label.select'), 
    value: '',
  }]
  this.BlList.map(item => {
    chart.push({
      value: item.VisitBillOfLadingPackagingId, 
      label: item.NroBl,
    })
  })
  return chart;
}

function TitleModal() {
  if (this.isEdit) {
    return `${this.$t('label.edit')} ${this.$t('label.DirectDischarge')}: ${this.DirectDischargeItem.OrderCode ?? ''}`;
  } else {
    return `${this.$t('label.nueva')} ${this.$t('label.DirectDischarge')}`;
  }
}

function ComputedPackaging() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.BillOfLadingJson.filter(item => item.Status != 0);
  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingName: item['PackagingName'+_lang] ?? 'N/A',
      Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
    }
  });
}

function Packagingfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'NroBl', label: 'Bl',  _classes: 'text-uppercase text-center', _style: 'width:30%;'},
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:32%;'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:15%;'},
    { key: 'Observation', label: this.$t('label.observation'), _classes: 'text-uppercase text-center', _style: 'width:15%;'},
    { key: 'options', label: '', _style: 'width:1%; min-width:90px; text-align:center;', sorter: false, filter: false },
  ];
}

function PackagingData() {
  return this.PackagingList.find(item => item.PackagingId == this.Packaging.PackagingId);
}

function BlData() {
  return this.BlList.find(item => item.VisitBillOfLadingPackagingId == this.Packaging.VisitBillOfLadingPackagingId);
}

export default {
  name: 'modal-direct-discharge',
  mixins: [ModalMixin, GeneralMixin],
  props: {
    modal: Boolean,
    isEdit: Boolean,
    DirectDischargeItem: Object,
  },
  components: {
    Money
  },
  data,
  directives: UpperCase,
  validations() {
    return DirectDischargeValidations(this.BlData?.RemainDispatch)
  },
  methods: {
    Services,
    submit,
    getBl,
    SubmitPackaging,
    DeletePackaging,
    EditPackaging,
    CloseModal,
    getData,
    getDateRange,
    validateDateRange,
    statusSelectColor,
    FormatNumber,
    CleanPackaging,
    Reset,
  },
  computed: {
    TransportOptions,
    DriverOptions,
    VehicleOptions,
    CiTypeOptions,
    PackagingOptions,
    BlOptions,
    TitleModal,
    ComputedPackaging,
    Packagingfields,
    PackagingData,
    BlData,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      dropItemReport: state => state.visitas.dropItemReport,   
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,
      user: state => state.auth.user,
      branch: state => state.auth.branch, 
      VisitId: state => state.visitas.VisitId,
    }),
  },
  watch: {
    modal: async function(NewVal) {
      this.$v.$reset();
      this.showModal = NewVal; 
      if(NewVal){
        this.$emit('Loading', true);
        await this.getDateRange();
        await this.Services();
        if (this.isEdit) {
          await this.getData(this.DirectDischargeItem);
        }
        this.$emit('Loading', false);
      }
    },
    StowagePlanningResourceId: function (NewVal) {
      if (!this.Start) {
        this.StowagePlanningDriverId = '';
        this.StowagePlanningVehicleId = '';
      }else{
        this.Start = false
      }
      if (NewVal && typeof NewVal == 'string') {
        let TransportData = this.TransportList.find(item => item.StowagePlanningResourceId == NewVal);
        if (TransportData) {
          this.DriverList = TransportData?.DriverJson ?? [];
          this.VehicleList = TransportData?.VehicleJson ?? [];
        } else {
          this.VehicleList = [];
          this.DriverList = [];
        }
      }else{
        this.VehicleList = [];
        this.DriverList = [];
      }
    }
  }
}
</script>
<style scoped>
.circle {
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #191a1b;
}
</style>