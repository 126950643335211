import { required } from 'vuelidate/lib/validators';
import { NumberFormater } from "@/_helpers/funciones";

export default (RemainDispatch) => {
  return {
    StowagePlanningResourceId: { required },
    StowagePlanningDriverId: { required },
    StowagePlanningVehicleId: { required },
    TransactionDate: { required },
    Destiny: {},
    Observation: {},
    Packaging: {
      Id: {},
      PackagingId: { required },
      VisitBillOfLadingPackagingId: { required },
      Quantity: { 
        required(Value){
          return NumberFormater.setNum(Value) > 0;
        },
        MaxValue(Value){
          if (!isNaN(RemainDispatch)) {
            return RemainDispatch >= NumberFormater.setNum(Value);
          } else {
            return true;
          }
        }
      },
      Observation: {},
    },
    BillOfLadingJson: { required },

    OrderJson: [
      'StowagePlanningResourceId',
      'StowagePlanningResourceId',
      'StowagePlanningDriverId',
      'StowagePlanningVehicleId',
      'TransactionDate',
      'Destiny',
      'Observation',
      'BillOfLadingJson'
    ]
  }
}