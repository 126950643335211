<template>
  <div class="container-fluid">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mb-3 mt-2">
      <CTabs variant="tabs" :active-tab="0" style="width: 100%;" class="my-2">
        <CTab :title="$t('label.DirectDischarge')">
          <CCol sm="12" class="d-flex align-items-center justify-content-end mt-3 mb-2">
            <CButton
              color="excel"
              size="sm"
              @click="onBtnExport(true)"
            >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
          </CCol>
          <CCol sm="12" class="d-flex align-items-center justify-content-end mb-3">
            <CButton
              color="add"
              v-c-tooltip="{ placement: 'top', content: $t('label.DirectDischarge') }"
              @click="ModalDirectDischarge = true"
            >
              <CIcon name="cil-playlist-add"/>&nbsp; {{ $t('label.nueva') }}
            </CButton>
          </CCol>

          <CRow class="m-0">
            <CCol sm="12" lg="6">
              <CSelect
                :label="$t('label.consignee')"
                addLabelClasses="text-right"
                :placeholder="$t('label.select')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-model="ClientTpId"
                :options="ConsigneeOptions"
              />
            </CCol>
            <CCol sm="12" lg="3">
              <CSelect
                label="BL"
                addLabelClasses="text-right"
                :placeholder="$t('label.select')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                v-model="VisitBillOfLadingId"
                :options="BlOptions"
              />
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol sm="12" lg="3">
              <div class="form-group form-row ">
                <label class="col-sm-12 col-lg-3 col-form-label text-right">{{$t('label.date')}}</label>
                <div class="input-group col-sm-12 col-lg-9">
                  <vue-datepicker 
                    type="datetime"
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="FromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                  >
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </div>
              </div>
            </CCol>
            <label class="col-sm-12 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="12" lg="4">
              <CRow>
                <div class="input-group col-sm-12 col-lg-7 mb-3">
                  <vue-datepicker 
                    type="datetime"
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="ToDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                  >
                    <template #icon-calendar>
                      <div style="display: none"></div>
                    </template>
                  </vue-datepicker>
                </div>
                <CCol class="col-sm-12 col-lg-auto col-xl-auto center-field" style="text-align: right;">
                  <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getVisitDispatchOrder(false)"
                  >
                    <CIcon name="cil-search" />
                  </CButton>
                  <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                  >
                    <CIcon name="cil-brush-alt" />
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol sm="12" lg="3" xl="3" class="center-field">
              <CInput
                :label="$t('label.filter')"
                :horizontal="{ label: 'col-sm-12 col-lg-3 col-xl-3 text-right', input: 'col-sm-12 col-lg-9 col-xl-9'}"
                :placeholder="$t('label.enterSearch')"
                @keyup="onQuickFilterChanged($event.target.value)"
                v-model="search"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="5" xl="5">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
              <CSelect
                :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
                :label="$t('label.recordsPerPage')"
                @change="onPageSizeChanged"
                :options="[50,100,500,1000]"
                required
                v-model="pageSize"
              >
              </CSelect>
            </CCol>
          </CRow>
          <CCol sm="12" lg="12" xl="12">
            <ag-grid-vue
              v-if="showGrid"
              style="width: 100%; height: 100vh;"
              class="ag-theme-alpine"
              :getRowStyle="getRowStyle"
              :getRowClass="getRowClass"
              :gridOptions="gridOptions"
              :defaultColDef="defaultColDef"
              :localeText="localeText"
              :columnDefs="columnDefs"
              :rowData="RowData"
              :suppressRowClickSelection="true"
              :groupSelectsChildren="true"
              :enableRangeSelection="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :paginationNumberFormatter="paginationNumberFormatter"
              @grid-ready="onGridReady"
              :getRowHeight="getRowHeight"
            >
            </ag-grid-vue>
          </CCol>
        </CTab>
      </CTabs>
    </CRow>
    <DirectDischarge
      :modal="ModalDirectDischarge"
      :isEdit="isEdit"
      :DirectDischargeItem="DirectDischargeItem"
      @Loading="Loading=$event"
      @Close="(ModalDirectDischarge=false, isEdit=false, DirectDischargeItem={})"
      @Update="refreshComponent()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import AgGrid from '@/_mixins/ag-grid';
import { DateFormater, trimRemoveSpecialCharacters, removeTags } from '@/_helpers/funciones';
import DirectDischarge from './modal-direct-discharge';

//Data
function data() {
  return {
    ModalDirectDischarge: false,
    gridOptions: null,
    paginationPageSize: null,
    paginationNumberFormatter: null,
    defaultColDef: null,
    gridApi: null,
    columnApi: null,
    Loading: false,
    isEdit: false,
    VisitBillOfLadingId: '',
    BlList: [],
    NroBl: '',
    ClientTpId: '',
    ClientName: '',
    ConsigneeList: [],
    DispatchOrderList: [],
    DirectDischargeItem: {},
    previousDate: '',
    laterDate: '',
    FromDay: '',
    ToDay: '',
    pageSize:'',
    search: '',
  };
}
//mounted
function beforeMount() {
  this.gridOptions = {
    onCellClicked: (event) => {
      if(event.colDef.colId == 'EditDirectDischarge'){
        this.DirectDischargeItem = event.data;
        this.isEdit = true;
        this.ModalDirectDischarge = true;
      }
    },
    onCellMouseOver: (event) => {
      if(event.colDef.colId == 'EditDirectDischarge'){
        tippy('#myEditDirectDischargeButton', {
          content: this.$t('label.edit')+' '+this.$t('label.DirectDischarge'),
        })
      }
    },
  };
  this.defaultColDef = {
    editable: false,
    resizable: true,
    sortable: true,
    minWidth: 150,
    flex: 1,
  };
  this.paginationPageSize = 100;
  this.paginationNumberFormatter = (params) => {
    return params.value.toLocaleString();
  };
}

//methods
async function VisitGeneralCargoBlDispatch() {
  await this.$http.get("VisitGeneralCargoBlDispatch-by-VisitId", {VisitId: this.Visit, ClientTpId: ''})
  .then(response => {
    let Data = response.data.data && response.data.data[0] ? response.data.data[0] : {};
    this.BlList = Data?.BillOfLandingJson ?? [];
    this.ConsigneeList = Data?.ConsigneeJson ?? [];
  }).catch( err => {
    this.BlList = [];
    this.ConsigneeList = [];
    this.$notify({
    group: 'container',
    title: '¡Error!',
    text: err,
    type: "error"
    });
  })
}

async function getVisitDispatchOrder () {
  this.Loading = true;
  let DispatchOrderJson = [{
    VisitId: this.Visit,
    VisitBillOfLadingId: this.VisitBillOfLadingId,
    ClientTpId: this.ClientTpId,
    DateStart: DateFormater.formatDateTimeWithoutSlash(this.FromDay),
    DateEnd: DateFormater.formatDateTimeWithoutSlash(this.ToDay),
  }];
  await this.$http.post("VisitDispatchOrder-list", DispatchOrderJson, { root: 'DispatchOrderJson' })
  .then(response => {
    let List = response.data.data ?? [];
    let ClientTpData = this.ConsigneeList.find(item => item.ClientTpId == this.ClientTpId);
    let BlData = this.BlList.find(item => item.VisitBillOfLadingId == this.VisitBillOfLadingId);
    this.NroBl = BlData?.NroBl ?? 'N/A';
    this.ClientName = ClientTpData?.ClientName ?? 'N/A';
    if (List && Object.keys(List[0]).length>1) {
      this.DispatchOrderList = List;
    }else{
      this.DispatchOrderList = [];
    }
  }).catch( err => {
    this.DispatchOrderList = [];
    this.$notify({
    group: 'container',
    title: '¡Error!',
    text: err,
    type: "error"
    });
  })
  .finally(() => {
    this.Loading = false;
  });
}

async function onBtnExport(valor) {
  this.Loading = true;
  let rowData = [];
  this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push({...node.data, Bl: node.data.Bl ? removeTags(node.data.Bl) : ''}));
  if(rowData.length !== 0) {
    let Filter = [
      'BL: '+this.NroBl,
      this.$t('label.consignee')+": "+this.ClientName,
      this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
      this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
    ]
    await this.getOrdersExcel(rowData,this.$t('label.DirectDischarge'),valor, Filter, 1);
  }else{
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error"
    });
  }
  this.Loading = false;
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function onGridReady(params) {
  this.gridApi = params.api;
  this.columnApi = params.columnApi;
}

function getRowHeight(params) {
  return params.data.rowHeight;
}

function getRowClass(params) {
  if (params.node.data.section=="header") {
    return 'bg-secondary font-weight-bold bg-gradient'
  }
  return 'bg-gradient';
}

function getRowStyle(params) {
  return { background: params.node.data.ColorStatus };
}

function onQuickFilterChanged(value) {
  this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
  this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

async function getDateRange(){
  if(this.itinerarySelected.Ata!=undefined){
    this.FromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
  }

  if(this.itinerarySelected.Ata==undefined){
    this.FromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
  }

  if(this.itinerarySelected.Atd!=undefined){
    this.ToDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
  }

  if(this.itinerarySelected.Atd==undefined){
    let currentDate = new Date();
    this.ToDay=DateFormater.formatTimeZoneToDateTime(currentDate);
    this.laterDate = currentDate;
  }
}

async function refreshComponent(All) {
  this.Loading = true;
  this.search = '';
  this.pageSize = '';
  this.FromDay = '';
  this.ToDay = '';
  this.VisitBillOfLadingId = '';
  this.NroBl = '';
  this.ClientTpId = '';
  this.ClientName = '';
  this.gridApi.paginationSetPageSize(parseInt(50));
  this.onQuickFilterChanged(this.search);
  await this.getDateRange();
  if (All) {
    await this.VisitGeneralCargoBlDispatch();
  }
  await this.getVisitDispatchOrder();
  this.Loading = false;
}

function Reset() {
  this.search = '';
  this.pageSize = '';
  this.FromDay = '';
  this.ToDay = '';
  this.VisitBillOfLadingId = '';
  this.ClientTpId = '';
  this.BlList = [];
  this.ConsigneeList = [];
  this.DispatchOrderList = [];
  this.DirectDischargeItem = {};
}

//computed
function BlOptions(){
  let chart = [{
    value: '',
    label: this.$t('label.select'),
  }]
  this.BlList.map((item) => {
    chart.push({
      value: item.VisitBillOfLadingId,
      label: item.NroBl,
    })
  })
  return chart;
}

function ConsigneeOptions(){
  let chart = [{
    value: '',
    label: this.$t('label.select'),
  }]
  this.ConsigneeList.map((item) => {
    chart.push({
      value: item.ClientTpId,
      label: item.ClientName,
    })
  })
  return chart;
}

function RowData(){
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.DispatchOrderList.map((item) => {
    let BillOfLadingJson = item.BillOfLadingJson ?? [];
    return {
      ...item,
      CumputedTransactionDate: item?.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      Bl: BillOfLadingJson.map(BillOfLading => {
        return `BL: ${BillOfLading?.NroBl ?? ''} - ${this.$t('label.packaging')}: ${BillOfLading['CommodityName'+_lang] && BillOfLading['CommodityName'+_lang].length > 10 ? trimRemoveSpecialCharacters(BillOfLading['CommodityName'+_lang], 10) : ''} - ${this.$t('label.quantity')}: ${BillOfLading?.Quantity ?? 0}`
      }).join(',<br> '),
      Driver: `${item.DriverName} (${item.DriverCi})`,
      CumputedAnulationDate: item?.AnulationDate ? DateFormater.formatDateTimeWithSlash(item.AnulationDate) : '',
      OrderStatusDs: item['OrderStatusDs'+_lang] ?? '',
      EditDirectDischarge: item.FgActOrder,
      rowHeight: BillOfLadingJson.length != 0 ? (BillOfLadingJson.length * 42) : 42,
    };
  });
}

function columnDefs(){
  let columnDefs = [
    {
      headerName: "",
      field: 'EditDirectDischarge',
      width: 45,
      minWidth: 45,
      maxWidth: 45,
      suppressMovable: true,
      lockPosition: true,
      colId: "EditDirectDischarge",
      cellRenderer: params => {
        if(params.value){
          return  `   
            <Button id="myEditDirectDischargeButton" class="btn mr-1 btn-edit btn-sm">                   
              <svg viewBox="0 0 22.93 22.93" role="img" class="c-icon" data-v-3be54f06="">
                <g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1">
                  <path id="_Color"  d="M4.78,22.93H0V18.15L14.09,4.06l4.77,4.78ZM20.23,7.48h0L15.45,2.7,17.78.37a1.29,1.29,0,0,1,1.8,0l3,3a1.29,1.29,0,0,1,0,1.8L20.23,7.48Z">
                  </path>
                </g></g>
              </svg>
            </Button>
          `
        }
      },
      cellClass: 'px-1 gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "Nro",
      headerName: "#",
      filter: 'agNumberColumnFilter',
      headerClass: 'center-cell-especial',
      suppressMovable: true,
      lockPosition: true,
      width: 70,
      maxWidth: 70,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "OrderCode",
      headerName: this.$t('label.noOrder'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 180,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center text-color-negrita',
    },
    {
      field: "CumputedTransactionDate",
      headerName: this.$t('label.date'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "CarrierAlias",
      headerName: this.$t('label.transport'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "LicensePlate",
      headerName: this.$t('label.vehicle'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "Driver",
      headerName: this.$t('label.driver'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 250,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "Bl",
      headerName: 'BL',
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 400,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
      cellRenderer: params => {
        if(params.value){
          return params.value;
        }
      },
    },
    {
      field: "Quantity",
      headerName: this.$t('label.totalQuantity'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "CumputedAnulationDate",
      headerName: this.$t('label.AnulationDate'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 200,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "TransaLoginAnulation",
      headerName: this.$t('label.AnulationLogin'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 200,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "DeviceCode",
      headerName: this.$t('label.device'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "TransaLogin",
      headerName: this.$t('label.user'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center',
    },
    {
      field: "OrderStatusDs",
      headerName: this.$t('label.status'),
      filter: 'agTextColumnFilter',
      headerClass: 'center-cell-especial',
      lockPosition: true,
      suppressMovable: true,
      minWidth: 170,
      cellClass: 'gb-cell d-flex justify-content-center align-items-center text-color-negrita',
    },
  ]
  return columnDefs;
}

export default {
  name: "direct-discharge-index",
  data,
  beforeMount,
  mixins: [ReportesVisitas, AgGrid],
  props: {
    currentModuleId: Boolean,
  },
  components: {
    DirectDischarge,
  },
  methods:{
    VisitGeneralCargoBlDispatch,
    getVisitDispatchOrder,
    onBtnExport,
    onGridReady,
    getRowHeight,
    getDateRange,
    validateDateRange, 
    onQuickFilterChanged,
    onPageSizeChanged,
    getRowStyle,
    getRowClass,
    refreshComponent,
    Reset,
  },
  computed:{
    BlOptions,
    ConsigneeOptions,
    RowData,
    columnDefs,
    ...mapState({
        user: state => state.auth.user,
        Visit: state => state.visitas.VisitId,
        dropItemReport: state => state.visitas.dropItemReport,
        itinerarySelected: state => state.visitas.itinerarySelected,
    }),
  },
  watch:{
    currentModuleId: function (newValue) {
      if (newValue) {
        this.refreshComponent(true)
      }else{
        this.Reset()
      }
    },
    showGrid: function (val) {
      if (val) {
        this.Loading = false;
      } else {
        this.Loading = true;
      }
    }
  }
};
</script>